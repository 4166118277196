import { template as template_acf6290de0334c90a26b48318d8ce147 } from "@ember/template-compiler";
const FKLabel = template_acf6290de0334c90a26b48318d8ce147(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
