import { template as template_e7c2e44a44ba492eb766962c8ef3ef06 } from "@ember/template-compiler";
const FKText = template_e7c2e44a44ba492eb766962c8ef3ef06(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
