import { template as template_f5ff0b237d2a43e6a1c8c67e40951166 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_f5ff0b237d2a43e6a1c8c67e40951166(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
