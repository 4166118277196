import { template as template_4ee347676e3a4e29bf3c355b44fcb82c } from "@ember/template-compiler";
const WelcomeHeader = template_4ee347676e3a4e29bf3c355b44fcb82c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
