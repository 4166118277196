import { template as template_df002fc7b6084d3a9314b16a4e6ecb09 } from "@ember/template-compiler";
import LightDarkImg from "discourse/components/light-dark-img";
const CategoryLogo = template_df002fc7b6084d3a9314b16a4e6ecb09(`
  <div class="category-logo aspect-image">
    <LightDarkImg
      @lightImg={{@category.uploaded_logo}}
      @darkImg={{@category.uploaded_logo_dark}}
    />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CategoryLogo;
