import { template as template_055c00d4f307436dbc6e777f44a2ba00 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import NewListHeaderControls from "discourse/components/topic-list/new-list-header-controls";
import raw from "discourse/helpers/raw";
export default class NewListHeaderControlsWrapper extends Component {
    @service
    site;
    @action
    click(e) {
        const target = e.target;
        if (target.closest("button.topics-replies-toggle.--all")) {
            this.args.changeNewListSubset(null);
        } else if (target.closest("button.topics-replies-toggle.--topics")) {
            this.args.changeNewListSubset("topics");
        } else if (target.closest("button.topics-replies-toggle.--replies")) {
            this.args.changeNewListSubset("replies");
        }
    }
    static{
        template_055c00d4f307436dbc6e777f44a2ba00(`
    {{#if this.site.useGlimmerTopicList}}
      <div class="topic-replies-toggle-wrapper">
        <NewListHeaderControls
          @current={{@current}}
          @newRepliesCount={{@newRepliesCount}}
          @newTopicsCount={{@newTopicsCount}}
          @noStaticLabel={{true}}
          @changeNewListSubset={{@changeNewListSubset}}
        />
      </div>
    {{else}}
      <div
        {{! template-lint-disable no-invalid-interactive }}
        {{on "click" this.click}}
        class="topic-replies-toggle-wrapper"
      >
        {{raw
          "list/new-list-header-controls"
          current=@current
          newRepliesCount=@newRepliesCount
          newTopicsCount=@newTopicsCount
          noStaticLabel=true
        }}
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
