import { template as template_32d85a741e50415691e5fe6f7442ffd9 } from "@ember/template-compiler";
const SidebarSectionMessage = template_32d85a741e50415691e5fe6f7442ffd9(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
